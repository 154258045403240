import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import MUIDataTable from "mui-datatables";

import RolAccess from "./../../common/rolAccess";
import AddAttachmentsModal from "../modals/addAttachment";
import ProcessingModal from "../modals/processingModal";
import * as actions from "./actions";
import "./Attachments.css";
import vertikalTheme from "../../../lib/theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CloudDownload, Edit } from "@material-ui/icons";
import moment from "moment";
import Swal from "sweetalert2";
import { NOT_CAPTURED } from "../../../lib/appConstants";
import Spinner from "../../common/spinner";

class Attachments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showProcessingModal: false,
      currentProjectName: "",
    };
  }

  async componentDidMount() {
    const { certProjectId, actions, projectId, insuredId, fromInsuredTab } =
      this.props;
    await actions.fetchAttachments({
      orderBy: "dateCreated",
      orderDirection: "DESC",
      ...(projectId && { projectId }),
      ...(insuredId && { insuredId }),
      ...(fromInsuredTab && { fromInsuredTab }),
    });

    const { list } = this.props.attachments;
    if (certProjectId && insuredId && list.length > 0) {
      const currentProject = list.find(
        (project) => project.ProjectID == certProjectId
      );
      if (currentProject && currentProject.ProjectNumber) {
        this.setState({
          currentProjectNumber: currentProject.ProjectNumber,
        });
      }
    }
  }

  openModal = () => {
    const {CFRole} = this.props.profile;
    if((CFRole && CFRole.Name !== 'Holder View Only') || !CFRole){
      this.props.actions.setShowModal(true);
    }
  };

  closeModalAndRefresh = (update) => {
    this.props.actions.setShowModal(false);
  };

  closeModal = () => {
    this.props.actions.setShowModal(false);
  };

  renderButtonAddAttachment() {
    let component = (
      <a onClick={this.openModal} className="nav-btn nav-bn icon-add">
        {this.props.local.strings.attachments.list.addBtn}
      </a>
    );
    return component;
  }

  renderButtonDownloadDocument(Url) {
    let component = (
      <a href={Url} target="_blank">
        <CloudDownload />
        {this.props.local.strings.attachments.list.downloadLink}
      </a>
    );
    return component;
  }
  garbageDocument = (e, document, newGarbageStatus) => {
    const {CFRole} = this.props.profile;
    if((CFRole && CFRole.Name !== 'Holder View Only') || !CFRole){
      const garbageTitle = newGarbageStatus === 1 ? "Garbage" : "Ungarbage";
      const garbageText = newGarbageStatus === 1 ? "garbage" : "ungarbage";
  
      Swal({
        title: `${garbageTitle} Project`,
        text: `Are you sure you want to ${garbageText} document # ${document.DocumentID}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2E5965",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          const payload = {
            documentId: document.DocumentID,
            certificateId: document.CertificateID,
            projectInsuredId: document.ProjectInsuredID,
            holderId: document.HolderID,
            projectId: document.ProjectID,
            insuredId: document.InsuredID,
            garbage: newGarbageStatus,
          };
          this.props.actions.garbageDocument(payload, async (success) => {
            const {
              certProjectId,
              actions,
              projectId,
              insuredId,
              fromInsuredTab,
            } = this.props;
            await actions.fetchAttachments({
              orderBy: "dateCreated",
              orderDirection: "DESC",
              ...(projectId && { projectId }),
              ...(insuredId && { insuredId }),
              ...(fromInsuredTab && { fromInsuredTab }),
            });
  
            const { list } = this.props.attachments;
            if (certProjectId && insuredId && list.length > 0) {
              const currentProject = list.find(
                (project) => project.ProjectID == certProjectId
              );
              if (currentProject && currentProject.ProjectNumber) {
                this.setState({
                  currentProjectNumber: currentProject.ProjectNumber,
                });
              }
            }
          });
        }
      });
    }
  };
  renderGarbageDocumentLink(document) {
    const { garbagedDocument, nonGarbagedDocument } =
      this.props.local.strings.documents.documentsList;
    const garbageFlag = document.GarbageFlag;

    return !garbageFlag ? (
      <a
        onClick={(e) => this.garbageDocument(e, document, 1)}
        className="cell-table-link"
      >
        {nonGarbagedDocument}
      </a>
    ) : (
      <a
        onClick={(e) => this.garbageDocument(e, document, 0)}
        className="cell-table-link"
        style={{ color: "#F00" }}
      >
        {garbagedDocument}
      </a>
    );
  }

  openProcessingModal = (e, documentData, canViewDataEntry) => {
    const {CFRole} = this.props.profile;
    //console.log('currentDocument: ', documentData);
    if((CFRole && CFRole.Name !== 'Holder View Only') || !CFRole ){
      documentData.canViewDataEntry = canViewDataEntry;
      this.setState({ showProcessingModal: true, currentDocument: documentData });
    }

  };

  closeProcessingModal = () => {
    this.setState({ showProcessingModal: false, currentDocument: null });
  };

  render() {
    const {
      nameColumn,
      documentTypeColumn,
      dateUploadedColumn,
      uploadedByColumn,
      holderNameColumn,
      projectColumn,
      projectNumberColumn,
      documentStatusColumn,
      processDocument,
      documentColumn,
      TranscribedByColumn,
      topLayerColumn,
    } = this.props.local.strings.attachments.list;

    const { projectArchived, insuredArchived } = this.props;
    let { showModal } = this.props.attachments;
    const {list, fetching} = this.props.attachments;

    const columns = [
      {
        name: "DocumentID",
        label: documentColumn,
      },
      {
        name: "TranscribedBy",
        label: TranscribedByColumn,
        options: {
          customBodyRender: (value, tableMeta) => {
            const hasCertificate = list[tableMeta.rowIndex].CertificateID != null;
            return (
              <React.Fragment>
                {hasCertificate ? (value || NOT_CAPTURED) : ''}
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "FileName",
        label: nameColumn,
      },
      {
        name: "DateCreated",
        label: dateUploadedColumn,
        options: {
          customBodyRender: (value) => {
            return (
              <React.Fragment>
                {value ? moment(value).utc().format("MM/DD/yyyy:hh:mm:ss A") : ""}
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "UploadedByUser",
        label: uploadedByColumn,
      },
    ];

    const { insuredId } = this.props;
    const {CFRole} = this.props.profile;
    const showHolderAndProject = insuredId ? true : false;
    if (showHolderAndProject) {
      columns.push({
        name: "HolderName",
        label: holderNameColumn,
      });
      columns.push({
        name: "ProjectNumber",
        label: projectNumberColumn,
        options: {
          filterList: this.state.currentProjectNumber
            ? [this.state.currentProjectNumber]
            : "",
        },
      });
      columns.push({
        name: "ProjectFullName",
        label: projectColumn,
      });
    }

    if (insuredId) {
      columns.push({
        name: "DocumentType",
        label: documentTypeColumn,
        options: {
          filterType: "dropDown",
        },
      });
      columns.push({
        name: "DocumentStatus",
        label: documentStatusColumn,
      });
    }

    columns.push({
      name: "TopLayer",
      label: topLayerColumn,
    });
    columns.push({
      name: "Garbage",
      label: "Garbage",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const document =
          list.find(doc => doc.DocumentID === tableMeta.rowData[0]);
          const {
            DocumentUrl,
            Archived,
            Exempt,
            GarbageFlag,
            canViewDataEntry,
          } = document;
          const canSeeProcessLink = !Archived && !Exempt && !GarbageFlag;
          return (
            <React.Fragment>
              {this.renderGarbageDocumentLink(document)}
            </React.Fragment>
          );
        },
      },
    });

    columns.push({
      name: "",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const attachment = list.find(doc => doc.DocumentID === tableMeta.rowData[0]);
          const {
            DocumentUrl,
            Archived,
            Exempt,
            GarbageFlag,
            canViewDataEntry,
          } = attachment;
          const canSeeProcessLink = !Archived && !Exempt && !GarbageFlag;
          return (
            <React.Fragment>
              <RolAccess
                masterTab="insured_documents"
                sectionTab="download_document"
                component={() => this.renderButtonDownloadDocument(DocumentUrl)}
              ></RolAccess>
              {canSeeProcessLink ? (
                <a
                  onClick={(e) =>
                    this.openProcessingModal(e, attachment, canViewDataEntry)
                  }
                  className="cell-table-link"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Edit />
                  {processDocument}
                </a>
              ) : null}
            </React.Fragment>
          );
        },
      },
    });

    const options = {
      download: false,
      filterType: "textField",
      selectableRowsHeader: false,
      selectableRowsHideCheckboxes: true,
      print: false,
      responsive: "standard",
      fixedHeader: true,
      tableBodyHeight: "410px",
      jumpToPage: true,
      setTableProps: () => {
        return {
          size: "small",
        };
      },
      setFilterChipProps: (colIndex, colName, filterValue) => {
        return {
          label: `${columns[colIndex]["label"].toUpperCase()} ${filterValue}`,
        };
      },
      onFilterChange: (changedColumn, filterList, type) => {
       //console.log("filterList", filterList);
      },
      customSort: (data, dataIndex, rowIndex) => {
        if (dataIndex === 2) {
          return data.sort((a, b) => {
            const dateA = new Date(a.data[dataIndex]).getTime();
            const dateB = new Date(b.data[dataIndex]).getTime();
            return (dateA < dateB ? -1 : 1) * (rowIndex === "desc" ? -1 : 1);
          });
        } else {
          return data.sort((a, b) => {
            return (
              (a.data[dataIndex] < b.data[dataIndex] ? -1 : 1) *
              (rowIndex === "desc" ? -1 : 1)
            );
          });
        }
      },
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow[2] = currentRow[2] ? moment(currentRow[2]).format("MM/DD/yyyy") : "";
        currentRow.forEach(col => {
          if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },
    };
    return (
      <React.Fragment>
        <div className="list-view admin-view-body attachments-list">
          <Modal
            show={showModal}
            onHide={this.closeModal}
            className="add-item-modal add-entity-small"
          >
            <Modal.Body>
              <AddAttachmentsModal
                close={this.closeModalAndRefresh}
                projectId={this.props.projectId}
                insuredId={this.props.insuredId}
                onHide={this.closeModal}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.showProcessingModal}
            onHide={this.closeProcessingModal}
            className="add-item-modal add-hc"
          >
            <Modal.Body className="add-item-modal-body mt-0">
              <ProcessingModal
                onHide={this.closeProcessingModal}
                close={this.closeProcessingModal}
                document={this.state.currentDocument}
                currentUploadData={{
                  holderId: this.props.holderId,
                }}
                onSubmit={this.onProcessing}
              />
            </Modal.Body>
          </Modal>

          <div className="attachments-list-header">
            <div>
              {!projectArchived && !insuredArchived && (
                <RolAccess
                  masterTab="insured_documents"
                  sectionTab="add_attachment"
                  component={() => this.renderButtonAddAttachment()}
                ></RolAccess>
              )}
            </div>
          </div>
          {
            !fetching ? (
              <MuiThemeProvider theme={vertikalTheme.getMuiTheme()}>
              <MUIDataTable
                title={"Insured Documents"}
                data={list}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
            ): <Spinner/>
          }
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attachments: state.attachments,
    local: state.localization,
    profile: state.login.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);